<template>
    <div class='mine'>
        <!-- 显示红包图 -->
        <RedEnv @recieve="goLottery" :isRecive="false" :show="showRedEnv" @closeDia="showRedEnv = false"/>
        <!-- 头部 -->
        <div class="mine_head" :class="{'nh_head_height': isNH}">
            <!-- 红包 -->
            <div v-if="redEnvelope > 0" class="mine_head_red" @click="showRedEnv=true">
                <img src="../assets/imgs/mine/red_ball_icon.gif" alt="">
            </div>
            <!-- 用户信息 -->
            <div class="mine_head_info">
                <img src="../assets/imgs/mine/avator.png" alt="">
                <div v-if="isVisitor">
                    <p class="big_bold" @click="showRegisterBox = true">实名注册</p>
                </div>
                <div class="badge" v-else>
                    <p>{{hiddenCardNo(betCardNo)}} <span class="eye_icon" @click="hidden = !hidden"><img :src="eye"/></span></p>
                    <p>{{hiddeName(username)}}<span class="member_level">白银会员</span></p>
                    <img class="badge_img" v-show="!isVisitor" src="../assets/imgs/mine/mine_badge.gif"/>
                </div>
                
            </div>
            <!-- 账户金额 -->
            <div v-show="showNHStyle" class="nh">
                <div class="nh_items" v-for="(item, index) in headItems" :key="index">
                    <p>{{hiddenValue(item.value)}}</p>
                    <p>{{item.text}}</p>
                </div>
            </div>
            <ul v-show="!showNHStyle" class="mine_count">
                <li class="mine_count_item" v-show="index == 0" v-for="(item, index) in headItems" :key="index">
                    <p>
                        <span>可用余额(元):<b>{{hiddenValue(item.value)}}</b></span>
                        <img @click="showDetail = !showDetail"  v-show="showDetailInfo && showDetailInfo.length>0" src="../../src/assets/imgs/question_red_icon.png"/>
                    </p>
                    <!-- <p @click="handleEvent(item)">{{item.text}}<span v-show="showDetailInfo && showDetailInfo.length>0">{{item.name == 'all' ? '?': ''}}</span></p> -->
                </li>
                <div v-show="showDetail && showDetailInfo && showDetailInfo.length>0" class="mine_count_detail">
                    <h3>账户余额</h3>
                    <p v-for="(item, index) in showDetailInfo" :key="index">
                        {{item.text}}: {{item.value}}元
                    </p>
                </div>
            </ul>
        </div>
        <!-- 操作内容 -->
        <div class="mine_content">
            <!-- 操作按钮 -->
            <ul class="mine_content_buttons" v-if="showHandleButtons.length > 0 && inWhiteList">
                <li 
                    class="mine_content_buttons_item" 
                    v-for="(button, index) in showHandleButtons" 
                    @click="handleEvent(button)"
                    :key="index">
                    {{button.label}}
                </li>
            </ul>
            <!-- 操作选项卡 -->
            <ul class="mine_content_items">
                <li class="mine_content_items_item" 
                    v-for="(item, index) in showHandleItems" 
                    @click="handleEvent(item)"
                    :key="index">
                    <img :src="item.img" alt="">
                    <p>
                        <a v-if="item.type == 'phone'" :href="item.number"></a>
                        {{item.label}}
                    </p>
                </li>
            </ul>
        </div>
        <!-- 注册 -->
        <Register 
            @close="close" 
            @signInEvent="signIn($event)" 
            :contentInfo="registerInfo2"
            :showExtra="false" 
            :isLogin="false" 
            :showDialog="showRegisterBox"/>
        <Footer :footerItems="getFootTab()"/>
    </div>
</template>
<script>
import { reactive, toRefs, inject, computed, onMounted } from 'vue'
import RedEnv from '../components/RedEvn.vue'
import { fundDetail, register, loginout} from '../utils/api'
import { jumpTo, 
    login, 
    getUserName, 
    inputIsAllFill, 
    toast, 
    getVisitor, 
    getMobile, 
    getQueryStr, 
    getStoreIdHiddenEvent,
    sleep, 
    getFootTab,
    getUserInfo,
    changeLotteryRecardStatus} from '../utils/util'
import Register from '../components/Login.vue'
import { onBeforeRouteLeave } from 'vue-router'
import Footer from '../components/Footer.vue'
export default {
    name: '',
    components: { Register, RedEnv, Footer },
    setup() {
         const state= reactive({
             username: '',
             betCardNo: '',
             count: '100.00',
             headItems: [
                 {name: 'all', text: '账户余额(元)', value: '0'},
                 {name: 'bonusmoney', text: '奖金余额(元)', value: '0'},
             ],
             handleButtons: [
                 {label: '在线充值', route: '/recharge', eventName: 'recharge', img: require('../assets/imgs/mine/mine_recharge.png')},
                 {label: '二维码充值', route: '/qrcode', eventName: 'rechargeQRCode', img: require('../assets/imgs/mine/mine_recharge_qrcode.png')}
             ],
             handleItems: [
                 {label: '投注记录', route: '/lotteryRecard', eventName: 'rechargeRecard', type: 'button', img: require('../assets/imgs/mine/mine_lottery_recard.png')},
                 {label: '资金明细', route: '/recharge-recard', eventName: 'moneyDetail', type: 'button', img: require('../assets/imgs/mine/mine_money_detail.png')},
                 {label: '银行卡', route: '/bankCards', eventName: 'bankCards', type: 'button', img: require('../assets/imgs/mine/mine_my_bank.png')},
                 {label: '联系我们', route: '/contact', eventName: 'phoneNumber', type: 'phone', img: require('../assets/imgs/mine/mine_service.png')},
                 {label: '奖金提现', route: '/withdraw', eventName: 'drawback', type: 'button', img: require('../assets/imgs/mine/mine_draw_back.png')},
                 {label: '账户安全', forward: true, route: '/accountSafe', eventName: 'accountSecure', type: 'button', img: require('../assets/imgs/mine/mine_secure.png')}
             ],
             showHandleButtons: [],
             showHandleItems: [],
             registerInfo2: [
                  {
                        title: '实名注册',
                        tips: '根据国家管理条例相关规定和要求，您在使用电话投注前，需使用有效身份信息进行实名认证。若无实名认证，您将无法进行短信投注，同时身份证也是领取中奖奖金的唯一凭证，请仔细填写您的身份信息，完成实名认证。',
                        inputItems: [
                                    {label: '请输入真实姓名', value: '', icon: require('../assets/imgs/phone_icon_gray.png')},
                                    {label: '请输入身份证号', value: '', icon: require('../assets/imgs/clock_icon_gray.png')},
                                    {label: '请设置密码', value: '', icon: require('../assets/imgs/clock_icon_gray.png')},
                                ],
                        submitButton: {label: '注册', eventName: 'signInEvent'}
                    },
             ],
             detailInfo: [
                 {label: 'baseMoney', value: 0, text: '本金'},
                 {label: 'bonusMoney', value: 0, text: '奖金'},
                 {label: 'presentMoney', value: 0, text: '赠金'},
                 {label: 'limitMoney', value: 0, text: '预付限额'},
             ],
             showRedEnv: false,
             showDetailInfo: [],
             isLogin: false,
             showDetail: false,
             isVisitor: true,
             showRegisterBox: false,
             hidden: true,
             isNH: false,
             inWhiteList: false,
             redEnvelope: 0,
             eyeIcon: [require('../assets/imgs/mine_eye_close.png'), require('../assets/imgs/mine_eye_open.png')]
        })
        const loginStatus = inject('loginStatus')
        const setLoginStatus = loginStatus.setIsLogin
        const author = getQueryStr('author')
        const eye = computed(() => {
            return state.hidden ? state.eyeIcon[0] : state.eyeIcon[1]
        })
        const showNHStyle = computed(() => {
            return state.isNH
        })
        if (author) {
            state.showRegisterBox = true
        }
        onMounted(() => {
            changeLotteryRecardStatus(true)
        })
        onBeforeRouteLeave((to, from, next) => {
            if (from.path.indexOf('lotteryRecard') > -1) {
                from.meta.keepAlive = true
            }else {
                 from.meta.keepAlive = false
            }
            console.log(from.meta)
            next()
        })
        state.isVisitor = getVisitor() == 'true'
        const name = getUserName()
        async function initShowItems() {
            try {
                const userInfo = await getUserInfo(true)
                if (!userInfo) {
                    return
                }
                state.inWhiteList = userInfo.open == 1
                const storeId = userInfo.storeId
                // 获取会员店的隐藏事件
                const hiddenEvent = getStoreIdHiddenEvent(storeId)
                state.handleButtons.forEach(ele => {
                    if (hiddenEvent.indexOf(ele.eventName) == -1) {
                        state.showHandleButtons.push(ele)
                    }
                })
                // console.log(state.showHandleButtons)
                state.handleItems.forEach(ele => {
                    if (hiddenEvent.indexOf(ele.eventName) == -1) {
                        state.showHandleItems.push(ele)
                    }
                })
                // console.log(state.showHandleItems)
            } catch(e) {
                console.error(e)
            }
        }
        function goLottery() {
            jumpTo('/chooseNumber')
        }
        function hiddeName(name) {
           return name && name.replace(/.(?=.)/g, '*') || ''
        }
        function hiddenCardNo(number) {
            return number && number.replace(/^(\d{3})\d*(\d{4})$/,"$1****$2") || ''
        }
        if (state.isVisitor) {
            state.showHandleButtons = state.handleButtons
            state.showHandleItems = state.handleItems
        } else {
            initShowItems()
            userInfo()
            loadData()
        }
        // 获取用户信息
        async function userInfo() {
            try {
                const info = await getUserInfo()
                if (!info) {
                    return
                }
                state.username = info.realName
                state.betCardNo = info.userNo
                state.isNH = info.storeId == '613'
                // if (state.isNH) {
                    
                // }
            } catch(e) {
                console.error(e)
            }
        }
       
        // 获取资金信息
        async function loadData() {
            try {
                const result = await fundDetail()
                if (result.code == 0) {
                    const data = result.data
                    state.headItems = data.rowList.filter(ele => ele.name != 'redMoney')
                    const red = data.rowList.filter(ele => ele.name == 'redMoney')
                    state.redEnvelope =  red && red[0] && red[0].value/100
                    if (state.headItems.length == 1) {
                        state.headItems.push(...data.foldList)
                    } else {
                        state.showDetailInfo = data.foldList
                    }
                }
                // console.log(result)
            } catch(e) {
                console.error(e)
            }
        }
        function recharge() {
            if (state.isLogin) {
                jumpTo('/recharge')
            } else {
                jumpTo('/login')
            }
        }
        function close() {
            state.showRegisterBox = false
        }
        function handleEvent(event) {
            if(state.isVisitor) {
                if (event.forward) {
                    jumpTo(event.route)
                } else {
                    toast('实名注册后才能查看')
                    return
                }  
            }
            if (event.route) {
                // changeLotteryRecardStatus(true)
                jumpTo(event.route)
            } else {
                switch(event.name) {
                case 'all':
                    state.showDetail = !state.showDetail
                    break
                }   
            }
        }
        // 注册
        async function signIn() {
            try{
                const items = state.registerInfo2[0].inputItems
                if (!inputIsAllFill(items)) {
                    // toast('填写完整才能提交')
                    return
                } else {
                    const params = {
                        mobile: getMobile(),
                        userName: items[0].value,
                        icardNumber: items[1].value,
                        betPasswd: items[2].value
                    }
                    await loginout()
                    const result = await register(params)
                    if (result.code == 0) {
                        toast('注册成功,需重新登录')
                        await sleep(200)
                        setLoginStatus(false)
                        jumpTo(`/login=true`)
                    }
                    // loadData()
                }
            } catch(e) {
                console.error(e)
            }
        }
        function hiddenValue(value, l = 4) {
            if (state.hidden) {
                let arr = ''
                while(l) {
                    arr+='*'
                    l--
                }
                return arr
            } else {
                return value
            }
        }
        return {
            ...toRefs(state),
            login,
            recharge,
            handleEvent,
            name,
            signIn,
            close,
            hiddenValue,
            eye,
            showNHStyle,
            hiddeName,
            hiddenCardNo,
            goLottery,
            getFootTab
        }
    },
}
</script>
<style lang='scss' scoped>
.nh_head_height {
    height: 190px !important;
}
.mine {
    .big_bold {
        font-size: 20px;
        border-bottom: solid 1px;
    }
    .red_env {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
        }
    }
    .eye_icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        img {
            width: 100% !important;
            margin-left: 5px !important;
            height: auto;
        }
    }
    .member_level {
        display: inline-block;
        background: linear-gradient(to bottom, #F1F1F1 10%, #C2BEBE);
        border-radius: 3px;
        margin-left: 13px;
        font-size: $font-less;
        padding: 4px 4px;
        line-height: 1em;
        color: #6B6B6B;
    }
    &_head {
        background: url('../assets/imgs/mine_bg.png') top center no-repeat;
        background-size: cover;
        height: 160px;
        color: #fff;
        padding: 30px $margin-big*2 0px $margin-big*2;
        // position: relative;
        &_red {
            position: absolute;
            right: 10px;
            top: 45px;
            width: 40px;
            img {
                width: 100%;
                height: auto;
            }
        }
        &_info {
            display: flex;
            align-items: center;
            font-size: $font-big;
            line-height: 1.5em;
            .badge {
                position: relative;
                p {
                    &:first-child {
                        font-size: $font-max;
                        padding: 5px 0;
                    }
                    &:nth-child(2) {
                        display: flex;
                        align-items: center;
                        font-size: $font-big;
                    }
                }
                &_img {
                    position: absolute;
                    top: 2px;
                    right: -90px;
                }
            }
            img {
                &:first-child {
                    width: 61px;
                    height: 61px;
                    overflow: hidden;
                    margin-right: 12px;
                }
                &:last-child {
                    width: 29px;
                    margin-left: 30px;
                    height: auto;
                }
            }
        }
    }
    &_count {
        display: flex;
        text-align: center;
        justify-content: space-between;
        padding-top: 25px;
        position: relative;
        &_item {
            width: 100% !important;
            padding-top: 8px;
            p {
                display: flex;
                display: flex;
                align-items: center !important;
                 font-size: $font-big !important;
            }
            b {
                font-size: $font-max;
            }
            img {
                width: 20px;
                height: auto;
                margin-left: 5px;
            }
        }
        &_detail {
            box-shadow: 1px 3px 4px #efefef;
            position: absolute;
            width: 150px;
            background: #fff;
            left: 65px;
            top: 63px;
            color: $gray-middle;
            text-align: left;
            padding: $margin-big;
            border-radius: $box-radius-small;
            z-index: 9;
            h3 {
                color: #000;
                font-size: $font-middle;
                padding-bottom: 8px;
            }
            p {
                line-height: 1.8em;
            }
            &::after {
                position: absolute;
                content: '';
                top: -8px;
                left: 50px;
                width: 0;
                height: 0;
                border-left: 13px solid transparent;
                border-right: 13px solid transparent;
                border-bottom: 20px solid #fff;

            }
        }
        li {
            width: 50%;
            p {
                &:first-child {
                    font-size: $font-max;
                }
            }
        }
        
    }
    .nh {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            text-align: center;
            &_items {
                padding-top: 20px;
                width: 33.3%;
                &:first-child {
                    width: 100%;
                    text-align: center;
                    padding-top: 10px;
                }
                p {
                    font-size: $font-big;
                    &:last-child{
                        font-size: $font-small;
                    }
                }
            }
        }
    &_content {
        border-radius: $box-radius-middle $box-radius-middle 0px 0px;
        padding: $margin-big;
        &_buttons {
            display: flex;
            justify-content: space-between;
            padding: $margin-big*2 $margin-big;
            border-radius: $box-radius-small;
            font-size: $font-big;
            background: #fff;
            li {
                width: 48%;
                color: #fff;
                text-align: center;
                border-radius: $box-radius-small;
                padding: $margin-big 0;
                &:first-child {
                    background: linear-gradient(to right, $theme-dark, $theme-light);
                }
                &:last-child {
                    background: linear-gradient(to right, $yellow-dark, $yellow-light);
                }
            }
        }
        &_items {
            background: #fff;
            margin-top: $margin-big;
            display: flex;
            flex-wrap: wrap;
            padding-top: 4px;
            padding: $margin-big*2 $margin-big;
            border-radius: $box-radius-small;
            &_item {
                position: relative;
                width: calc(31%);
                margin-right: 3%;
                background: $bg-gray;
                text-align: center;
                margin-bottom: 10px;
                padding: $margin-big 0;
                border-radius: $box-radius-small;
                a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                &:nth-child(3n) {
                    margin-right: 0;
                }
                img {
                    width: 40px;
                }
                font-size: $font-middle;
                // font-weight: 700;
                p {
                    margin-top: 5px;
                }
            }
        }
    }
}
</style>